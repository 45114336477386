import * as React from "react";

// React
import { useState, useEffect } from "react";
import { AdvancedImage } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";

// Shared Components
import TextModal from "../components/shared/modal/Modal";
import LoadingButton from "../components/shared/loading-button/LoadingButton";
import Footer from "../components/shared/footer/Footer";
import Header from "../components/shared/header/Header";
import Seo from "../components/seo";

// Services
import { contactSubmission } from "../services/contactAPI";

export default function ComingSoon() {
  const [selected, setSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [icon, setIcon] = useState("");
  const [description, setDescription] = useState("");
  const [largeScreen, setLargeScreen] = useState(true);
  const [loading, setLoading] = useState(false);

  // Modal
  function toggleModalOpen() {
    setIsOpen(!isOpen);
  }

  //ADD LISTENER TO CHECK THE SCREEN SIZE
  function checkScreenSize() {
    if (window.innerWidth <= 768) {
      setLargeScreen(false);
    } else {
      setLargeScreen(true);
    }
  }

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  // Create a Cloudinary instance and set your cloud name.
  const cld = new Cloudinary({
    cloud: {
      cloudName: "kirkwork",
    },
  });
  // define our cloudinary images
  const HeaderImg = cld
    .image("v1646228267/Aritae/ComingSoon/mountain-lake_pkgttd.jpg")
    .quality("auto");
  const HeaderMobile = cld
    .image("v1646228267/Aritae/ComingSoon/mountain-lake-mobile_joafvq.jpg")
    .quality("auto");

  // which contact type is selected
  const handleSelect = (event) => {
    setSelected(event.target.value);
  };

  //email sent from contact form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const email = event.target.email.value;
    const firstName = event.target.firstName.value;
    const lastName = event.target.lastName.value;
    const phone = event.target.phone.value;
    const subject = event.target.subject.value;
    const message = event.target.message.value;

    let postBody = JSON.stringify({
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      Phone: phone,
      Subject: subject,
      ContactType: selected,
      Message: message,
      PageName: "ComingSoon",
    });

    contactSubmission(postBody).then(async (response) => {
      const data = await response;
      if (data.dbSuccess === true && data.endUserEmailSuccess === true) {
        // modal success
        setIsOpen(true);
        setIcon("success");
        setDescription(
          "Thanks for your interest in ARITAE! Please check your inbox for updates."
        );
        setLoading(false);
        event.target.reset();
      } else {
        // modal error
        setIsOpen(true);
        setIcon("failure");
        setDescription("Something went wrong. Please try again.");
        setLoading(false);
        event.target.reset();
      }
    });
  };

  return (
    <div id="root">
      <Header />
      <Seo title="Coming Soon" description="Coming soon and contact page." />

      <div className="relative flex-1">
        {/* Modal */}
        <TextModal
          isOpen={isOpen}
          close={toggleModalOpen}
          icon={icon}
          description={description}
        />
        <div className="absolute inset-0">
          <div className={"absolute inset-0 opacity-50 bg-stone-500"} />

          <AdvancedImage
            cldImg={largeScreen ? HeaderImg : HeaderMobile}
            alt="Beautiful blue nature shot of a mountain and a lake."
            className="h-full w-full object-cover"
          />
        </div>
        <div className="relative flex justify-center py-24">
          <div className="w-full my-auto md:max-w-3xl">
            <div className="flex flex-col items-start justify-start w-full h-full p-10 lg:p-16 xl:p-24">
              <h4 className="w-full text-3xl text-white font-bold mb-4">
                WE'RE COMING VERY SOON
              </h4>
              <p className="text-sm text-white">
                We are currently hard at work to get our digital platform up and
                running, if you are interested in getting started now or just
                have general questions please contact us.
              </p>
              <form onSubmit={handleSubmit} className="relative h-full w-full">
                <div className="relative w-full mt-10 space-y-4">
                  <div className="relative">
                    <label htmlFor={"firstname"}>
                      <input
                        type="text"
                        className="block w-full border-none p-3 text-sm bg-white bg-opacity-80"
                        placeholder="First Name *"
                        required
                        id="firstname"
                        name="firstName"
                      />
                    </label>
                  </div>
                  <div className="relative">
                    <label htmlFor={"lastname"}>
                      <input
                        type="text"
                        className="block w-full border-none p-3 text-sm  bg-white bg-opacity-80"
                        placeholder="Last Name *"
                        required
                        name="lastName"
                        id={"lastname"}
                      />
                    </label>
                  </div>
                  <div className="relative flex justify-between">
                    <label htmlFor={"email"} className={"inline-block w-6/12"}>
                      <input
                        type="email"
                        className=" w-full border-none p-3 text-sm  bg-white bg-opacity-80"
                        placeholder="Email Address *"
                        required
                        name="email"
                        id="email"
                      />
                    </label>
                    <label
                      htmlFor={"phone"}
                      className={"inline-block w-6/12 ml-2"}
                    >
                      <input
                        type="text"
                        className="w-full border-none p-3 text-sm  bg-white bg-opacity-80"
                        placeholder="Phone Number"
                        name="phone"
                        id={"phone"}
                      />
                    </label>
                  </div>
                  <div className="relative flex justify-between">
                    <label
                      htmlFor={"subject"}
                      className={"inline-block w-6/12"}
                    >
                      <input
                        type="text"
                        className="w-full border-none p-3 text-sm  bg-white bg-opacity-80"
                        placeholder="Subject"
                        name="subject"
                        id={"subject"}
                      />
                    </label>
                    <label
                      htmlFor={"dropdown"}
                      className={"inline-block w-6/12 ml-2"}
                    >
                      <select
                        onChange={handleSelect}
                        id="dropdown"
                        className=" text-gray-600 w-full border-none p-3 text-sm  bg-white bg-opacity-80"
                      >
                        <option value="NA" defaultValue hidden>
                          I am a
                        </option>
                        <option value="player">Player</option>
                        <option value="coach">Coach</option>
                        <option value="parent">Parent</option>
                        <option value="other">Other</option>
                      </select>
                    </label>
                  </div>
                  <div className="relative">
                    <label htmlFor={"message"}>
                      <textarea
                        type="textarea"
                        className="block w-full border-none p-3 text-sm  bg-white bg-opacity-80"
                        placeholder="Message"
                        name="message"
                        id={"message"}
                      />
                    </label>
                  </div>
                  <div className="relative">
                    {loading ? (
                      <LoadingButton />
                    ) : (
                      <button className="inline-block w-1/2 md:w-1/4 p-3 text-sm font-bold text-center text-gray-600 transition duration-200 bg-white hover:bg-gray-100 ease">
                        SUBMIT
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
